import PropTypes from "prop-types"
import { useState, useEffect } from "react"

// third-party
import ReactApexChart from "react-apexcharts"

// project import
import { formatNumber } from "utils/helper"

//styling
import styles from "./styles.module.scss"
import moment from "moment"

const style = {
  colors: "#6F767E",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "12px",
  fontFamily: "Inter",
}

const LineChart = ({
  data,
  gridLines = false,
  name = "",
  height = 290,
  day,
  month,
  yLabel,
}) => {
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState({
    chart: {
      height,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: name === "interval" ? 4 : 2,
      colors: ["#3668CC"],
    },
    //   title: {
    //     text: 'Product Trends by Month',
    //     align: 'bottom'
    //   },

    markers: {
      size: name === "interval" ? 0 : 4,
      shape: "circle",
      colors: ["#3668CC"],
    },
    grid: {
      borderColor: "#EFEFEF",
      xaxis: {
        lines: {
          show: gridLines,
        },
      },
      yaxis: {
        lines: {
          show: gridLines,
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
        color: "#78909C",
      },
      title: {
        text: yLabel,
        style: { ...style, color: "#6F767E" },
      },
      labels: {
        style,
        offsetX: -17,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          ...style,
          colors: "#6F767E",
        },
        formatter: function (value) {
          return formatNumber(value, "en-US", 3)
        },
      },
    },
    tooltip: {
      enabled: true,
      intersect: false,
      followCursor: false,
      theme: "dark",
      custom: function ({ series, dataPointIndex }) {
        const { xAxis } = data
        const value = series?.[0]?.[dataPointIndex]
        const date = xAxis?.[dataPointIndex]

        return `
           <div class=${styles.tooltipContainer}>
            <div class=${styles.labelContainer}>${
          name === "interval"
            ? `${month} ${day} (${date})`
            : moment(date, "YYYY-MM-DD").format("DD MMM YYYY")
        }</div>
            <div class=${styles.valueContainer}><div class=${
          styles.legend
        }></div><div class=${
          name === "interval" ? styles.value1 : styles.value
        }>${
          name === "interval"
            ? `KW: ${formatNumber(value, "en-US", 4)}`
            : formatNumber(value)
        }</div></div>
           </div>
          `
      },
    },
  })

  useEffect(() => {
    setSeries([
      {
        name: "Desktops",
        data: data.yAxis,
      },
    ])

    setOptions({
      ...options,
      xaxis: {
        categories:
          name === "interval"
            ? data.xAxis
            : data?.xAxis?.map(el => moment(el, "YYYY-MM-DD").format("MMM")),
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style,
        },
      },
    })
  }, [data])

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={height}
    />
  )
}

LineChart.propTypes = {
  color: PropTypes.string,
  yTitle: PropTypes.string,
  gradient: PropTypes.string,
  lineColor: PropTypes.string,
  data: PropTypes.object,
}

export default LineChart
