import { call, put, takeEvery } from "redux-saga/effects"
import { getLDPFail, getLDPSuccess } from "./actions"
import { toast } from "react-toastify"
import axiosServices from "helpers/axios"
import moment from "moment"

const { GET_LDP_REQUEST } = require("./actionTypes")

const callLDP = async ({ id, date }) =>
  await axiosServices.get(`${process.env.REACT_APP_LDP_URL}/${id}?date=${date}`)

function* fetchLDP({ payload: data }) {
  try {
    const response = yield call(callLDP, data)

    let xAxis = []
    let rawData = response?.data?.data || []

 

    let metersData = _.groupBy(rawData, "meterid")
    metersData = _.mapValues(metersData, group => _.map(group, 'kw'));
    const sumByMeterHour = _.mapValues(_.groupBy(rawData, 'meterhour'), arr => _.sumBy(arr, 'kw'));
    xAxis= Object.keys(sumByMeterHour)?.map(el => moment(el, "HH:mm:ss.SSSSSSS").format("h A"))
    const allMeters = Object.values(sumByMeterHour);
    const meterIds = Object.keys(metersData);
    meterIds.unshift('All')
    metersData.All = allMeters;


    yield put(getLDPSuccess({ metersData, xAxis, meterIds, rawData }))
  } catch (error) {
    let errorMessage = error?.response?.data?.message || "Something went wrong"
    if (errorMessage === "Invalid Token") {
      errorMessage =
        "Session has expired due to inactivity. Please login again."
    }

    toast.error(errorMessage, {
      autoClose: 3000,
    })
    yield put(getLDPFail(error?.response))
  }
}

function* ldpSaga() {
  yield takeEvery(GET_LDP_REQUEST, fetchLDP)
}

export default ldpSaga
